.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@keyframes pulse {
  0% {
    transform: scale(1);
  }
  20% {
    transform: scale(1.15);
  }
  40% {
    transform: scale(1);
  }
  100% {
    transform: scale(1); /* Добавляем паузу на этом этапе */
  }
}

.image-animation {
  animation: pulse 6s ease-in-out infinite; /* Увеличиваем общее время */
}
.vidgetswa, .vidgetste, .vidgetsph {
  position: fixed;
  right: 35px;
  
}
.widwa, .widte, .widph {
  width:3.6vw;
}
.vidgetswa {
  bottom: 12vw;
}
.vidgetste {
  bottom: 6.4vw;
}
.vidgetsph {
  bottom: 0.5vw;
}
.widbut{
  background-color: rgba(255, 255, 255, 0);
  border-color: rgba(255, 255, 255, 0);
  margin-bottom: 1rem;
  --bs-btn-color: #ffffff00;
    --bs-btn-bg: #0d6dfd02;
    --bs-btn-border-color: #0d6dfd00;
    --bs-btn-hover-color: #fff00;
    --bs-btn-hover-bg: #0b5dd700;
    --bs-btn-hover-border-color: #0a57ca00;
    --bs-btn-focus-shadow-rgb: 49, 132, 253;
    --bs-btn-active-color: #fff00;
    --bs-btn-active-bg: #0a57ca00;
    --bs-btn-active-border-color: #0a52be00;
    --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
    --bs-btn-disabled-color: #fff00;
    --bs-btn-disabled-bg: #0d6efd00;
    --bs-btn-disabled-border-color: #0d6efd00;
    box-shadow: 0px 10px 30px 10px rgba(255, 255, 255, 0.211);
    padding: 0;
    border-radius: 123vw;
}
.yamap{
  height:25vw;
  border-radius:25px;
  overflow: hidden;
}
@media (min-width: 1px) and (max-width: 990px) {
  .yamap{
    height:15rem;
    
  }
}
@media (min-width: 1px) and (max-width: 990px) {

.widwa, .widte, .widph {
  width:3rem;
}
.vidgetswa {
  bottom: 8rem;
}
.vidgetste {
  bottom: 4rem;
}
.vidgetsph {
  bottom: 0.5rem;
}
  }

