body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #0A0E17;
  color:#FFFFFF;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.logo {
  width: 10em;
}
.navbar-collapse {
  flex-grow: 0.7;
}
.nav-link {
  margin-left: 1em;
  margin-right: 0.7em;
}

.firstsqueare {
  flex-wrap: nowrap;
  background-size: auto;
  margin-top: 2em;
}
.mainblock {
  display: flex;
  flex-direction: column;
  margin-left: calc(+.5 * var(--bs-gutter-x));
  }
 
  .flag {
    width: 5em;
    align-content: flex-end;
  }
  .textalign {
  align-content: space-around;
  padding-right: 0;
  font-size: 4vw;
  }
  .textal {
    padding:0;
  }
  .textblock {
    padding: 2em;
    background-color: #5b647670;;
  border-radius: 2em;
  }
  .vertical-line {
    width: 1px;
    height: 100%;
    background-color: #0346CB;
    }
    .preim {
      background-color: #1C1C1C;
      border-radius: 0px 0px 2em 2em;
      flex-wrap: nowrap;
      height: fit-content;
      padding: 15px 2rem 15px 2rem;
      position: relative;
      bottom: 3vw;
    }
    .preimcol {
      text-align: left;
      text-align: start;
      font-size: 1vw;
    }
    .textalig {
      text-align: start;
    }
    .dost {
      font-size: 4vw;
    }
    .hover-outline-btn {
      transition: all 0.3s ease-in-out;
      background-color: #0346CB;
      color:#FFFFFF
    }
    .hover-outline-btn:hover {
      background-color: transparent; /* Убираем фон */
      border: 1px solid #0346CB; /* Добавляем контур (цвет по умолчанию для primary) */
      color: #0346CB; /* Изменяем цвет текста на цвет границы */
    }
    .border-line {
      border-right: 1px solid #0346CB;
    }
    .btnn {
      width:100%;
      height:100%;
      border-radius: 28px;
    }
    .rowbutton {
      height: 3.7rem;
      margin-top: auto;
    }
    .columnvert {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }
    .USA {
      padding-bottom: 3vw;
    }
    .nadpost {
      padding-top: 2vw;
    }
    .columnvertfirst {
      margin-left: auto;
    }
    .mobilecar {
      position: absolute;
      z-index: -1;
      top: 10%;
      left:0;
    }

    /* Первого блока для мобилок и планшетов */
    .mobilefirst {
      position: relative;
     display: flex;
     text-align: left;
     flex-direction: column;
    }
    
    
    
    .mobileflag {
    width:49px;
    }
    .mobiletextal {
      padding-left:0;
    }
    @media (min-width: 200px) and (max-width: 575px) {
    .btnn {
      width:100%;
      height:100%;
      border-radius: 30px;
      font-size: 3.2vw;
    }
    .rowbutton {
      margin-top: 15vw;
    }
    .mobilepreim {
      font-size: 0.75rem;
      margin-top: 10vw;
    }
    .mobilenadpost {
      padding-top: 13vw;
      padding-bottom: 2vw;
      font-size: 5vw;
    }
    .mobiledost {
      font-size: 10vw;
      padding-top: 9vw;
    }
    .mobiletextalign {
      font-size: 10vw;
    } 
    .mobilepreimcol {
      padding-top: 3vw;
      font-size: 3.5vw;
    }
    .mobileflag {
      width:15vw
  }
  .mobilecar {
    position: absolute;
    z-index: -1;
    top: 6%;
    left:0;
}
    }

@media (min-width: 575px) and (max-width: 990px) {
  .btnn {
    width:100%;
    height:100%;
    border-radius: 30px;
    font-size: 2.5vw;
  }
  .rowbutton {
    margin-top: 1vw;
  }
  .mobilepreim {
    font-size: 0.75rem;
    margin-top: 2vw;
  }
  .mobilenadpost {
    padding-top: 8vw;
    padding-bottom: 2vw;
    font-size: 3vw;
  }
  .mobiledost {
    font-size: 8vw;
    padding-top: 1vw;
  }
  .mobiletextalign {
    font-size: 8vw;
  } 
  .mobilepreimcol {
    padding-top: 1vw;
    font-size: 2.5vw;
  }
  .mobileflag {
    width:15vw
}
.mobilecar {
  position: absolute;
  z-index: -1;
  top: 4%;
  left:0;
}
}

/* Стили каруселей марок авто */
.continuous-carousel .react-multi-carousel-track {
  animation: scroll-left 25s linear infinite ; /* Непрерывная анимация в обратную сторону */
}

@keyframes scroll-left {
  0% {
    transform: translateX(0); /* Начальная позиция */
  }
  100% {
    transform: translateX(-50%); /* Полный сдвиг влево */
  }
}
.continuous-carousel-right .react-multi-carousel-track {
  animation: scroll-right 25s linear infinite reverse; /* Непрерывная анимация в обратную сторону */
}

@keyframes scroll-right {
  0% {
    transform: translateX(0); /* Начальная позиция */
  }
  100% {
    transform: translateX(-50%); /* Полный сдвиг влево */
  }
}
.carousel-item-padding {
  padding: 0 15px; 
}
@media (min-width: 200px) and (max-width: 400px) {
  .carousel-item-padding {
    padding: 0 5px; 
  }
}


.razdelzagl {
  text-align: left;
  margin-top: 9vw;
  font-weight: 500;
  font-size: 35px;
  margin-bottom: 2.3vw;

}
.razdelzagl1 {
  text-align: left;
  margin-top: 3vw;
  font-weight: 500;
  font-size: 35px;
  margin-bottom: 3.5vw;

}
@media (min-width: 200px) and (max-width: 575px) {
  .razdelzagl {
    text-align: left;
    margin-top: 9vw;
    font-weight: 500;
    font-size: 20px;
    margin-bottom: 2.3vw;
  
  }
  .razdelzagl1 {
    text-align: left;
    margin-top: 4vw;
    font-weight: 500;
    font-size: 20px;
    margin-bottom: 2.3vw;
  
  }
}
@media (min-width: 575px) and (max-width: 990px) {
  .razdelzagl {
    text-align: left;
    margin-top: 9vw;
    font-weight: 500;
    font-size: 5vw;
    margin-bottom: 2.3vw;
  
  }
  .razdelzagl1 {
    text-align: left;
    margin-top: 4vw;
    font-weight: 500;
    font-size: 5vw;
    margin-bottom: 2.3vw;
  
  }
}
@media (min-width: 1300px) and (max-width: 4000px) {
  .razdelzagl, .razdelzagl1 {
   font-size: 40px;
  }
}

.continuous-carousel-right {
  margin-top: 6vw;
}
/* Стили карточки авто */
.carcard, .caruscar {
  background-color: #202329;
  
}
.NameCar, .god, .probeg, .cena {
  text-align: left;
  color: #FFFFFF;
}
.godname, .probegname {
  text-align: right;
  color: #FFFFFF;
}
.god, .probeg {
  color: #7B7B7B;
}
.card {
  border-color: #202329;
  overflow: hidden;
  border-radius: 30px;
  background-color: #202329;
}
.NameCar {
  font-size:0.88rem;
  margin-top: 2vw;
}
.carousel-item-padding-car {
  padding: 0 10px;
}

@media (min-width: 464px) and (max-width: 615px) {
  .NameCar {
    font-size: 3.6vw;
    margin-top: 3vw;
  }
}
@media (min-width: 1024px) and (max-width: 4000px) {
  
.NameCar, .rowgod, .rowprobeg  {
  margin-bottom: 2vw;
}
.NameCar {
  font-size: 1.6vw;;
}
.godname, .probegname, .god, .probeg, .carbutton {
  font-size: 1.4vw;
}
.cena {
  font-size: 1.8vw;
}
  }
  .carbutton {
    width: 100%;
  }
  .buttoncat{
    margin-top: 2vw;
  }
  .importNSK {
    font-size: 3.5vw;
    
  }
  .aboutcomp {
    font-size: 2.2vw;
      font-weight: 600;
      margin-bottom: 1.5vw
  }
  .aboutcompone, .aboutcomptwo {
    margin-bottom: 1vw;
  }
  @media (min-width: 200px) and (max-width: 990px) {
  .importNSK {
    font-size: 19px;
    margin-bottom: 4vw;
    text-align: left;
  }
  .aboutcomp {
    font-size: 18px;
      font-weight: 600;
      margin-bottom: 2vw;
  }
  .aboutcompone, .aboutcomptwo {
    margin-bottom: 3vw;
    font-size: 16px;
  }
}
/* Стили блока преимуществ */
.divpreim {
  background-color: #111929;
  text-align: left;
  padding: 1vw;
  border-radius: 1vw;
  padding-left: 2.5vw;
  padding-right: 2.5vw;
}
.zaglpreim {
  font-size: 23px;
  font-weight: 600;
  margin-bottom: 0.5vw;
}
.textpreim {
  font-size: 18px;
  font-weight: 300;
}
.bak {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: -1;
  width: 100%;
  pointer-events: none; /* Чтобы не мешать интерактивным элементам */

}
@media (min-width: 990px) and (max-width: 1250px) {
  .zaglpreim {
    font-size: 20px;
    font-weight: 600;
    margin-bottom: 0.5vw;
  }
  .textpreim {
    font-size: 15px;
    font-weight: 300;
}
}
@media (min-width: 200px) and (max-width: 990px) {

  .zaglpreim {
    font-size: 18px;
    font-weight: 600;
    margin-bottom: 0.5vw;
  }
  .textpreim {
    font-size: 16px;
    font-weight: 300;
}
}
.dot {
  background-color: #0346CB;
  border-radius: 50%;
  width: 8px;  
  height: 8px;
  display: inline-block;
}
.podpiska {
  font-size: 17px;
  font-weight: 400;
}
/*Стили этапов работы*/
.etapyrow {
  background-color: #202329;
  border-radius: 20px;
  padding: 1vw;
  text-align: left;
}
.etapynumb {
  font-size: 18px;
  font-weight: 600;
}
.etapyzagl {
  font-size: 19px;
  font-weight: 600;
  padding-bottom: 1.3vw;
}
.etapytext {
  font-size: 17px;
  font-weight: 300;
}
.etapyrow1 {
  background-color: #111929;
  border-radius: 20px;
  padding: 1vw;
  text-align: left;
  margin-top: 2vw;
  margin-bottom: 2vw;
}
.accordion {
  --bs-accordion-btn-color: #838487 var(--bs-body-color); 
  --bs-accordion-btn-bg: #202329 var(--bs-accordion-bg);
  --bs-accordion-active-bg: #091323;
}
.accordion-item {
  background-color: #202329;
  color:#FFFFFF;
  text-align: left;
}
.accordion-button:not(.collapsed) {
  color: #cfcfcf;
}
.rowform {
  background-image: url("./img/Bmwform.png");
  background-repeat: no-repeat;
  background-size: cover;
}
.bg-body-tertiary {
  background-color: rgba(8, 8, 8, 0.942) !important;
  border-radius: 25px;
  box-shadow: 0px 10px 30px 0px rgba(255, 255, 255, 0.2);
}
.nav-link {
  color:#ffffff;
}
.fixeds {
  width: 100%;
  position: fixed;
  top: 0px;
  z-index: 9999;
  /*Задаём время и характер анимации для фона меню */
  transition:all 0.6s cubic-bezier(0, 0, 0.8, 1.0);
 }
 .displaynone {
  display:none;
 }
.headheader {
  background-color: rgba(255, 255, 255, 0.181) !important;
  border-radius: 25px;
  -webkit-box-shadow: 0px 17px 10px 5px rgba(255, 255, 255, 0);
-moz-box-shadow: 0px 17px 10px 5px rgba(255, 255, 255, 0);
box-shadow: 0px 17px 10px 5px rgba(255, 255, 255, 0);
}
.custom-link:hover {
  color: #0346CB !important;
}

/* Изменение цвета для активной вкладки */
.custom-link.active {
  color: #0346CB !important;
}
.carousel-img {
  height: 300px; /* Задаем фиксированную высоту */
  width: auto; /* Ширина подстраивается автоматически, чтобы сохранить соотношение сторон */
  object-fit: cover; /* Обеспечивает обрезку изображения по нужной высоте, сохраняя пропорции */
  display: block;
  margin: 0 auto; /* Центрует изображение в карусели */
  border-radius: 1vw;
}
.NameModelCar {
  font-size: 1.5vw;
}
@media (min-width: 464px) and (max-width: 615px) {
.NameModelCar {
 font-size: 1.4rem;
}
}
@media (min-width: 200px) and (max-width: 464px) {
  .NameCar {
    font-size: 1.6rem;
    margin-top: 2vw;
}
.NameModelCar {
  font-size: 1.4rem;
 }
}
@media (min-width: 614px) and (max-width: 990px) {
  .NameCar {
    font-size: 1.6rem;
    margin-top: 2vw;
}
.NameModelCar {
  font-size: 1.4rem;
 }
}

/* Блок по умолчанию скрыт на экранах больше 990px */
@media (min-width: 991px) {
  .responsive-form {
    display: none;
  }
}

/* Адаптивная форма для экранов от 200px до 990px */
@media (min-width: 1px) and (max-width: 990px) {
 .responsive-form1 {
  display:none;
 }
}
@media (max-width: 990px) {
  .responsive-form {
    display: block;
    padding: 1vw;
    background-color: white;
    border-radius: 1vw;
    margin-bottom: 1vw;
    color: black;
    text-align: left;
  }

  .custom-form {
    padding: 1vw;
    background-color: white;
    border-radius: 1vw;
  }

  .custom-form label {
    font-size: 4vw; /* Делаем текст крупнее для мобильных устройств */
  }

  .custom-form .form-control, 
  .custom-form .form-select {
    font-size: 3.5vw; /* Увеличиваем текст в полях ввода */
    padding: 1.5vw;
  }

  /* Устанавливаем адаптивные отступы и ширины для колонок */
  .custom-form .col,
  .custom-form .form-group {
    margin-bottom: 1vw;
  }

  /* Кнопка "Применить" на 100% ширины экрана */
  .custom-form .btnn {
    font-size: 4vw;
    padding: 2vw;
    width: 100%; /* Ширина кнопки 100% */
  }
}